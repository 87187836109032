<template>
  <div style="height: 951px" class="loading" v-if="event_data === undefined && !event_error">
    <Loading />
  </div>
  <section id="event" class="events-section ptb-50" v-else-if="event_data">
    <div class="container">
      <div class="section-title" v-if="eventHeader_data">
        <h2>{{eventHeader_data.data[0].heading}}</h2>
        <p>{{eventHeader_data.data[0].description}}</p>
        <router-link to="/events" class="background-btn green-btn">
          See Our Events
          <i class="flaticon-curve-arrow-1"></i>
        </router-link>
      </div>

      <div class="row">
        <div class="col-lg-6">
          <div v-if="!event_data.data.length" class="not-found">
            <img src="../assets/img/event.png" alt="">
            <h3>No upcoming Events</h3>
          </div>
          <carousel
            class="events-image-slider owl-theme"
            :dots="false"
            :items="1"
          >
            <div class="item" v-for="event in event_data.data" :key="event.id">
              <DefaultImage :id="event.image" :height="'540px'" />

              <div class="content">
                <span>
                  <span>{{moment(event.start_date) + ' ' + (event.start_time ? event.start_time : '')}}</span>
                  <span v-if="event.start_date !== event.end_date">- {{moment(event.end_date) + ' ' + (event.end_time ? event.end_time : '')}}</span>
                </span>
                <router-link :to="{ name: 'event', params: params({url: event.slug}) }">
                  <h3>{{event.title}}</h3>
                </router-link>
                <p v-if="event.description" v-html="trimMyString(event.description, 200)"></p>
              </div>
            </div>
          </carousel>
        </div>

        <div class="col-lg-6">
          <div class="events-item">
            <div v-if="eventHeader_data" class="white-space-pre-wrap" v-html="eventHeader_data.data[0].content"></div>
          </div>
        </div>
      </div>
    </div>

    <div class="events-shape">
      <div class="shape-one">
        <img src="../assets/img/events/shape.png" alt="image">
      </div>

      <div class="shape-two">
        <img src="../assets/img/events/shape2.png" alt="image">
      </div>
    </div>
  </section>
</template>

<script>
  import carousel from 'vue-owl-carousel';
  import moment from 'moment'
  import DefaultImage from "./DefaultImage";
  import api from "../services/api";
  import useSWRV from "swrv";
  import Loading from "./Loading";

  export default {
    name: "EventsArea",
    setup() {
      let {
        data: eventHeader_data,
        error: eventHeader_error,
        isValidating: eventHeader_isValidating
      } = useSWRV(() => `/items/event_header?status=published`, api.fetcher);

      let {
        data: event_data,
        error: event_error,
        isValidating: event_isValidating
      } = useSWRV(() => `items/events?status=published&filter[end_date][gte]=${moment().format('YYYY-MM-DD')}&sort=end_date&limit=3`, api.fetcher);

      return {
        event_data,
        event_error,
        event_isValidating,

        eventHeader_data,
        eventHeader_error,
        eventHeader_isValidating,
      }
    },
    components: {
      Loading,
      DefaultImage,
      carousel
    },
    watch: {
      event_data: function () {
        setTimeout(()=> {
          let prev = document.querySelectorAll('.events-image-slider .owl-prev')[0];
          let next = document.querySelectorAll('.events-image-slider .owl-next')[0];
          if (next && prev) {
            prev.innerHTML = '<i class="flaticon-left-arrow"></i>';
            next.innerHTML = '<i class="flaticon-right-arrow"></i>';
          }
        }, 0)
      }
    },
    methods: {
      moment(date) {
        return moment(date).format('DD MMM YYYY');
      },
      params(newParams) {
        return {
          ...this.$route.params,
          ...newParams
        }
      },
      trimMyString(string, maxLength, start = 0) {
        if (string.length > maxLength) {
          let trimmedString = string.substr(start, maxLength)
          return (
              trimmedString.substr(
                  start,
                  Math.min(trimmedString.length, trimmedString.lastIndexOf(' '))
              ) + ' ...'
          )
        }
        return string
      },
    }
  }
</script>

<style scoped lang="scss">
  .not-found {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;

    h3 {
      color: #474747;
      font-weight: 600;
      margin-top: 15px;
    }
  }

  .section-title h2 {
    font-size: 35px;
  }

  @media (max-width: 992px) {
    .events-item {
      margin-top: 35px;
    }
  }
</style>
